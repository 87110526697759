/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.title-default {
  color: #ccc;
}

app-header {
  background-color: #fff;
}

ion-content {
  background-color: #fff;
}

.header-md::after {
  background: none;
}

.footer-md::before {
  background: none;
  height: 0px;
}

#loading {
  z-index: 9999;
  top: -61px;
  left: calc(100% - 100%);
  position: fixed;
  display: none;
}

input,
select,
textarea,
input[type="checkbox"] {
  background-color: #fff;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}
.alert-warning h5 {
  font-size: 20px !important;
}
.alert-warning ul {
  margin-top: 10px;
  margin-left: 50px;
  font-size: 18px !important;
}

div {
  font-size: 14px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

app-footer {
  z-index: 9999;
}

#formModal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d7c8c8;
  border-radius: 10px;
  padding: 30px;
  width: min-content;
  align-items: center;
  color: #2b344b;
  animation: iniciar 0.8s ease-out;
  pointer-events: all;
  text-align: center;
  font-size: 20px;
  font-weight: bold;

  .title {
    font-size: 1.8cm;
    margin-bottom: 10%;
  }
  .input {
    min-width: 280px;
    border-radius: 22px;
  }

  #modalTitulo {
    text-transform: uppercase;
    color: #0d01ff;
    font-weight: bold;
  }

  #modalText {
    font-size: 1.2rem;
    color: #000;
    text-transform: uppercase;
  }

  #btn {
    margin-top: 10%;
    width: 100%;
    text-align: center;
  }
  #btn button {
    font-family: "Poppins", sans-serif;
    background: #e86800;
    width: 100%;
    height: 70px;
    border: none;
    font-size: 1, 1cm;
    color: #fff;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
  }
  #btn button:hover {
    background: #050069;
  }
  @keyframes iniciar {
    0% {
      top: -20%;
      opacity: 0;
      pointer-events: none;
    }
    100% {
      top: 50%;
      opacity: 100%;
      pointer-events: all;
    }
  }
}

.force-close {
  position: absolute;
  background: #f9f9f9;
  display: block;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding: 4px;
  border: 1px solid;
  text-align: center;
  font-weight: bold;
}

.button-modal {
  top: -14px;
  right: -13px;
}

.button-popup {
  top: 10px;
  right: 10px;
}

.modal-default {
  border-radius: 20px !important;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: Arial, Helvetica, sans-serif;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999999;
  opacity: 1;
}
.modal:target {
  opacity: 1;
  pointer-events: auto;
}
.modal > div {
  width: 85%;
  position: relative;
  margin: 5% auto;
  padding: 15px 20px;
  background: #fff;
  color: #000;
  z-index: 999999999;
}
.modal button.fechar {
  float: right;
  margin-bottom: 18px;
  z-index: 99999999999;
}
